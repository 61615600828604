/**
 *
 * Custom function
 *
 */

(function ($) {

	/*
	 *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
	 */
	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function () {
			var $this = $(this);

			// se non ci sono link skippo l'elemento
			if( $this.find('a').length < 1 ) return;

			$this.css('cursor', 'pointer');
			$this.find('a').click(function (event) {
				event.preventDefault();
			});

			if ($this.find('a').attr('target') == '_blank') {
				$this.click(function () {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.click(function () {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	/*
	 *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
	 */
	function loadAsynchronousImage() {

		$('*[data-loadimg]').each(function () {
			var box = $(this),
				src = box.attr('data-loadimg'),
				alt = '',
				img = new Image();

			if (box.attr('data-alt')) {
				alt = box.attr('data-alt');
			}

			$(img).on('load', function () {
				box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
				$('img', box).hide().fadeIn();

			}).attr('src', src);
		});

		$('*[data-loadbackground]').each(function () {
			var box = $(this),
				src = box.attr('data-loadbackground');

			box.css({
				'background-image': 'url(' + src + ')',
				'background-repeat': 'no-repeat',
				'background-position': 'top center'
			});

			if (box.has(['data-position'])) {
				box.css({
					'background-position': box.attr('data-position'),
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			} else {
				box.css({
					'background-position': 'top center',
					'background-image': 'url(' + src + ')',
					'background-repeat': 'no-repeat'
				});
			}

			if (box.attr('data-repeat') == 'repeat') {
				box.css({
					'background-repeat': 'repeat'
				});
			} else {
				box.css({
					'background-repeat': 'no-repeat'
				});
			}
		});
	}

	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */
	function socialOpen() {
		$('[data-socialurl]').each(function () {
			var $this = $(this),
				url = $this.attr('data-socialurl');

			$this.click(function () {
				window.open(url, '', 'width=500, height=500');
			});

		});
	}

	/*
	 *	Funzione per l'apertura di una finestra modale tramite cookie
	 */
	function openModal() {
		if ($('#onloadmodal').length) {
			if (!$.cookie("openmodal")) {
				$('#onloadmodal').modal();
				$.cookie("openmodal", 'no', {
					path: '/'
				});
			}
		}
	}

	/*
	 *	Funzione per l'apertura di un iframe all'interno di una finestra modale
	 */
	function iframeModalOpen() {
		$('.videoModal').each(function () {
			// impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
			$('.modalButton').on('click', function (e) {
				var src = $(this).attr('data-src');
				var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
				var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

				var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

				// stampiamo i nostri dati nell'iframe
				$(".videoModal iframe").attr({
					'src': src,
					'height': height,
					'width': width,
					'allowfullscreen': ''
				});
			});

			// se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
			$(this).on('hidden.bs.modal', function () {
				$(this).find('iframe').html("");
				$(this).find('iframe').attr("src", "");
			});
		});
	}

	/*
	 *	Funzione per la formattazione delle tabelle con classe listino
	 *	tramite le classi di bootstrap
	 */
	function responsiveTable() {

		$('.entry-content table').each(function () {
			var $this = $(this);
			$this.addClass('table');
			$this.wrap('<div class="table-responsive"></div>');
		});

		if ($(window).width() <= 767) {

			$('#primary .table-responsive').prepend(
				'<span class="angle-scroll-left">' +
				'<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
				'</span>'
			);

			$('#primary .table-responsive').on('scroll', function (event) {
				var angleScrollLeft = $('.angle-scroll-left');

				if ($(this).scrollLeft() >= 1) {
					angleScrollLeft.addClass('fade-out');
				} else {
					angleScrollLeft.removeClass('fade-out');
				}
			});

			$('#primary').find('.table-responsive').each(function () {
				var table = $(this);
				$('.angle-scroll-left').click(function (event) {
					table.animate({
						scrollLeft: '+=60'
					}, 200);
				});
			});
		}
	}

	/*
	 *	Funzione per l'inizializzazione della mappa
	 */
	function initializeGmap() {
		$('*[data-location]').each(function () {
			var map = $(this),
				address = map.attr('data-location');
			map.setGMap({
				address: address
			});
		});
	}

	/*
	 * Funzione per l'attivazione del menu responsive
	 */
	function responsiveMenu() {
		$('li.current-menu-parent > a').addClass('clicked');
		$('li.current-menu-parent > a').next().addClass('open');

		$('.main-navigation').find('a[href="#"]').each(function () {
			$(this).click(function (event) {
				event.preventDefault();

				var link = $(this),
					submenu = link.next();
				$('ul.sub-menu').not(submenu).removeClass('open');
				submenu.toggleClass('open');

				$('li.menu-item-has-children > a').not(link).removeClass('clicked');
				link.toggleClass('clicked');
			});
		});

		var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

		$('.open-menu').click(function () {
			menuPrimaryOffcanvas.addClass('offcanvas-open');
			$('body').addClass('menu-open');
			//$('html').addClass('no-scroll');
		});

		$('.close-menu').click(function () {
			menuPrimaryOffcanvas.removeClass('offcanvas-open');
			$('body').removeClass('menu-open');
			$('ul.sub-menu').removeClass('open');
			$('li.menu-item-has-children > a').removeClass('clicked');

			$('li.current-menu-parent > a').addClass('clicked');
			$('li.current-menu-parent > a').next().addClass('open');
			//$('html').removeClass('no-scroll');
		});
	}

	/*
	 * Funzione per nascondere e visualizzare la testata allo scroll della pagina
	 */
	function scrollHeader() {
		$('#js-header').scrollHide({
			scrollDelta: 10,
			scrollOffset: 110,
			scrollClass: 'scrh-header',
			scrollClassHide: 'scrh-hide',
			onScroll: function (context, msg, currentTop) {
				if (currentTop !== 0) {
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

				if (currentTop === 0) {
					$('.menu-offcanvas').removeClass('offcanvas-open');
				}

			}
		});
	}

	/*
	 * Funzione per gestire il fake form allo scroll della pagina
	 */
	function scrollFake() {
		var formFake = $('#fake-form'),
			button = $('#fixed-cta'),
			menu = $('#site-menu-2');

		if ($(window).width() > 991 && formFake.length > 0) {

			// se esiste il form e non siamo a livello mobile (smartphones)

			var position = (formFake.offset().top + formFake.outerHeight()) - menu.outerHeight();

			$(window).scroll(function () {
				if ($(this).scrollTop() >= position) {

					button.addClass('reveal');

				} else {

					button.removeClass('reveal');
				}
			});

		}
	}

	/*
	 * Funzione per sostituire i br con spazio 
	 * sotto un certo breakpoint
	 */
	function replaceBr(selector, breakpoint) {
		if ( $(window).width() < breakpoint ) {
			$(selector).find('br').replaceWith(' ');
		}
	}

	/*
	 *  Funzioni per PhotoSwiper
	 */
	function addImageSizeForPhotoSwiper() {
		var thumbs = $('img[data-big-size]');
		thumbs.each(function (index) {
			if ($(this).parent('a').length > 0) {
				var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
				if (isImageLink) {
					big_size = $(this).data('big-size');
					link = $(this).parent('a');
					link.attr('data-size', big_size);
					link.wrap('<div class="single-image"></div>');
				}
			}
		});
	}

	function initPhotoSwiper(selector) {
		var galleryItems = $(selector);
		var pswpItems = [];

		if (galleryItems.find('a').length > 0) {
			galleryItems.each(function (index) {
				var dimensions = $(this).find('a').data('size').split('x');
				var width = parseInt(dimensions[0], 10);
				var height = parseInt(dimensions[1], 10);

				myImage = {
					src: $(this).find('a').attr('href'),
					w: width,
					h: height,
					msrc: $(this).find('img').attr('src')
				};

				pswpItems.push(myImage);
			});


			galleryItems.each(function (index) {
				$(this).click(function (evt) {
					evt.preventDefault();
					openPhotoSwipe(pswpItems, index);
				});
			});
		}
	}

	function openPhotoSwipe(galleryItems, index) {
		var options = {
			index: index
		};
		var pswpElement = $('.pswp')[0];

		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
		gallery.init();
	}

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").click(function () {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {

					if (target.attr('data-paddingTopScroll')) {
						var adjust = target.attr('data-paddingTopScroll');
					}
					else {
						var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
					}

					$('html, body').animate({
						scrollTop: target.offset().top - adjust
					}, 1000);

					return false;
				}
			}
		});
	}

	function startOwl(selector) {
		$(selector).each(function (arguments) {
			var owl = $(this);

			var color = owl.data('owl-color');
			var shadow = owl.data('owl-shadow');
			var start = owl.data('owl-start-position');

			if (owl.data('owl-color') === undefined) color = '#ff5d1b';
			if (owl.data('owl-shadow') === undefined) shadow = 'rgba(0,0,0,.15)';
			if (owl.data('owl-start-position') === undefined) start = 0;

			var params = {
				nav: owl.data('owl-navigation'),
				navSpeed: owl.data('owl-slide-speed'),
				autoplay: owl.data('owl-autoplay'),
				autoplayTimeout: owl.data('owl-autoplay-timeout'),
				autoplaySpeed: owl.data('owl-autoplay-speed'),
				dots: owl.data('owl-dots'),
				dotsSpeed: owl.data('owl-dots-speed'),
				loop: owl.data('owl-loop'),
				center: owl.data('owl-center'),
				autoWidth: owl.data('owl-auto-width'),
				margin: owl.data('owl-margin'),
				startPosition: start,
				autoHeight: false,
				lazyLoad: true,
				rtl: owl.data('owl-rtl'),
				animateOut: owl.data('owl-animation-out'),
				animateIn: owl.data('owl-animation-in'),
				navText: [
					'<svg xmlns="http://www.w3.org/2000/svg" width="46" height="83" viewBox="0 0 46 83"><path d="M42.05,8.28A3.05,3.05,0,1,0,37.73,4L.89,40.87a3.06,3.06,0,0,0,0,4.32L37.73,82.1a3.05,3.05,0,1,0,4.32-4.32L7.37,43Z" transform="translate(0 0)" fill="' + shadow + '"/><path d="M45.11,5.22A3.05,3.05,0,1,0,40.79.9L4,37.81a3.06,3.06,0,0,0,0,4.32L40.79,79a3.05,3.05,0,0,0,4.32-4.32L10.42,40Z" transform="translate(0 0)" fill="' + color + '"/></svg>',
					'<svg xmlns="http://www.w3.org/2000/svg" width="46" height="83" viewBox="0 0 46 83"><path d="M35.58,43,.89,77.78A3.05,3.05,0,0,0,5.21,82.1L42.05,45.19a3.06,3.06,0,0,0,0-4.32L5.21,4A3.05,3.05,0,0,0,.89,8.28Z" transform="translate(0 0)" fill="' + shadow + '"/><path d="M38.63,40,4,74.72A3.05,3.05,0,0,0,8.27,79L45.11,42.13a3.06,3.06,0,0,0,0-4.32L8.27.9A3.05,3.05,0,0,0,4,5.22Z" transform="translate(0 0)" fill="' + color + '"/></svg>'
				]
			};

			if (owl.data('owl-nav-prev') && owl.data('owl-nav-next')) {
				params['navText'] = [
					owl.data('owl-nav-prev'),
					owl.data('owl-nav-next')
				];
			}

			if (owl.data('owl-single-item')) {
				params['items'] = 1;
			} else {
				params['responsive'] = {
					0: {
						items: owl.data('owl-items-xs'),
					},
					768: {
						items: owl.data('owl-items-sm'),
					},
					992: {
						items: owl.data('owl-items-md'),
					},
					1200: {
						items: owl.data('owl-items-lg'),
					},
					1440: {
						items: owl.data('owl-items-xl'),
					}
				};
			}

			owl.owlCarousel(params);

			if (owl.data('owl-prev')) {
				$(owl.data('owl-prev')).click(function () {
					owl.trigger('prev.owl.carousel');
				});
			}

			if (owl.data('owl-next')) {
				$(owl.data('owl-next')).click(function () {
					owl.trigger('next.owl.carousel');
				});
			}
		});
	}

	function slideMobile() {
		if (!jQuery('body').hasClass('is-mobile')) return;

		var imgItem = jQuery('.header-slideshow .item img');
		var imgSingle = jQuery('.header-single-image img');
		var mql = window.matchMedia( "(orientation: portrait)" );

		// images gallery
		if (imgItem.length > 0) {
			if ( mql.matches ) {
				// Portrait orientation
				for (i = 0; i < imgItem.length; i++) {
					jQuery(imgItem[i]).attr("src", mobileSize.mobile[i]);
				}
			} else {
				// Landscape orientation
				for (i = 0; i < imgItem.length; i++) {
					jQuery(imgItem[i]).attr("src", mobileSize.tablet[i]);
				}
			}
		}

		// single image
		if (imgSingle.length > 0) {
			if ( mql.matches ) {
				// Portrait orientation
				imgSingle.attr("src", mobileSize.mobile[0]);
			} else {
				// Landscape orientation
				imgSingle.attr("src", mobileSize.tablet[0]);
			}
		}		
	}

	/*****************************************************************************************/

	function toConsole(string) {
		if ((typeof window.console == "undefined")) {
			alert(string);
		} else console.log(string);
	}

	function $j(argument) {
		var result = jQuery(argument);
		if (result.length === 0) return null;
		else return result;
	}

	function closePhotoswipe() {
		$('.pswp__button--close').click(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});

		$(window).scroll(function () {
			if ($('.pswp').hasClass('pswp--open')) {
				$('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
			}
		});
	}

	/*
	 * Funzione per utilizzare MixItUp da select
	 */
	function filterElements() {
		var container = '.mix-filter-support';

		if ($(container).length) {
			var mixer = mixitup(container, {
				selectors: {
					control: '[data-mixitup-control]'
				}
			});
		}
	}

	/*
	 * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
	 * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
	 * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
	 * ritorna un boleano.
	 */
	var mapsMobileUrl = {
		isAndroid: function () {
			return /(android)/i.test(navigator.userAgent);
		},
		isIos: function () {
			return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
		}
	};

	function injectorLinkMaps() {
		var buttonMaps = $('.dove-siamo-button').find('.navigator');

		if (mapsMobileUrl.isAndroid()) buttonMaps.attr('href', phpMapsMobileUrl.android);
		if (mapsMobileUrl.isIos()) buttonMaps.attr('href', phpMapsMobileUrl.ios);
		if (!mapsMobileUrl.isAndroid() && !mapsMobileUrl.isIos()) buttonMaps.hide();
	}

	function hideContent() {
		var $content = jQuery('.truncate-content');

		if (jQuery(window).width() < 992 && $content.length) {

			if (jQuery(window).width() < 768) {
				linecount = 10;
			}

			var linecount = 6;
			var readmore = 'Leggi tutto';
			var close = 'Chiudi';

			switch (lang) {
				case 'en':
					readmore = 'Read more';
					close = 'Close';
					break;

				case 'de':
					readmore = 'Weiterlesen';
					close = 'Schließen';
					break;

				case 'fr':
					readmore = 'Tout lire';
					close = 'Fermer';
					break;
			}

			$content.moreLines({
				linecount: linecount,
				buttontxtmore: readmore,
				buttontxtless: close,
				animationspeed: 250
			});
		}
	}

	function fixedHeader(){

		$(document).on( "scroll", function(e) {

			var fromTop_threasold = 400;
			if( $('.header-slideshow').length > 0 ) {
				fromTop_threasold = $('.header-slideshow').outerHeight();
			}

			if( $(e.target).scrollTop() > fromTop_threasold ) {
				$('body').addClass('scroll');
			} else {
				$('body').removeClass('scroll');
			}

		});
	}

	$j(document).ready(function () {

		allClick();
		responsiveTable();
		addImageSizeForPhotoSwiper();
		iframeModalOpen();
		responsiveMenu();
		filterElements();
		injectorLinkMaps();
		hideContent();
		startOwl('.owl-carousel');
		smoothScroll();
		slideMobile();

		closePhotoswipe();

		fixedHeader();

		// Countdown

		var timer = $('#timer');

		if (timer.length > 0) {
			timer.countdown({
				date: timer.data('date'),
				offset: +2, // UTC Timezone offset for Rome
				day: 'giorno',
				days: 'giorni',
				hour: 'ora',
				hours: 'ore',
				minute: 'minuto',
				minutes: 'minuti',
				second: 'secondo',
				seconds: 'secondi',
				hideOnComplete: true
			}, function () {
				
			}); 
		}

		// Data tables

		$('#documents-datatable').DataTable({
			language: {
				url: "https://cdn.datatables.net/plug-ins/1.11.3/i18n/it_it.json"
			},
			responsive: true
		});

		// Page regulation

		// var page = $('#page'),
		// 	headerHeight = $('#js-header').outerHeight();
		
		// page.css('padding-top', headerHeight);

		// Add privacy disclaimer

		var privacy = $('#privacy');

		if ( privacy.length > 0 ) {
			var disclaimer = '<div class="disclaimer">Dichiaro di aver letto la <a href="/privacy-policy/" target="_blank">privacy policy</a> e di accettarne le condizioni e di autorizzare il trattamento dei dati personali con il nuovo regolamento UE 2016/679 (GDPR).</div>';
			privacy.parent().append(disclaimer);
		}

		// Truncate text

		$('.shortize').moreLines({
			linecount: 4,
			buttontxtmore: '<span class="icon-edt-arrow-right-8"></span>',
			buttontxtless: '<span class="text-close">X</span>',
			animationspeed: 250
		});

		// Load more posts on click

		$("#loadMore").on('click', function(e) {
			e.preventDefault();
			
			// Init
			var that = $(this);
			var page = that.data('page');
			var newPage = page + 1;
			var ajaxurl = that.data('url');

			// Chiamata AJAX
			$.ajax({
				url: ajaxurl,
				type: 'post',
				data: {
					page: page,
					action: 'ajax_script_load_more'
				},
				error: function(response) {
					console.log(response);
				},
				success: function(response) {
					// Controllo
					if (response == 0) {
						// Controllo
						if ($("#no-more").length == 0) {
							$('#ajax-content').append('<div id="no-more"><h3>Non sono presenti altri articoli!</h3></div>');
						}
						that.hide();
					} else {
						that.data('page', newPage);
						$('#ajax-content').append(response);
					}
				}
			});
		});

		// Change header on scroll (no smartphones)

		// if ($(window).width() > 767) {
			$(window).scroll(function () {
				if ($(this).scrollTop() >= 100) {
					$('#js-header').addClass('moved');
				} else {
					$('#js-header').removeClass('moved');
				}
			});
		// }

		// Fix for Apple devices

		var ua = navigator.userAgent,
			iOS = /iPad|iPhone|iPod/.test(ua),
			iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

		// ios 11 bug caret position
		if (iOS && iOS11) {

			// Add CSS class to body
			$("body").addClass("iosBugFixCaret");
		}

		/* Fix per modale con contenuto dinamico */

		$('#feedModal').on('hidden.bs.modal', function (e) {
			$(this).removeData('bs.modal');
			$(".modal-content", this).empty();
		});

		if ($('main .entry-content iframe:not([id^="gform_ajax_frame"])').length) {

			$('main .entry-content iframe').each(function () {
				$(this).wrap('<div class="responsive-video"></div>');
			});
		}

	});


	$j(window).on('load', function () {

		/*
		 *	Attraverso il Google Loader carico il modulo mappa
		 */
		if ($('body').hasClass('page-template-template-mappa')) {

			$.getScript('https://www.google.com/jsapi', function () {
				if (url.key) {
					var map_params = 'language=' + url.lingua + '&key=' + url.key;
				}
				else {
					var map_params = 'language=' + url.lingua;
				}

				google.load('maps', '3', {
					other_params: map_params + '&libraries=places',
					callback: function () {
						initializeGmap();
					}
				});
			});
		}

		/*
		 * Inizializzazione gallerie per Photoswipe 
		 */
		var gallery = $('.gallery');

		if (gallery.length) {
			gallery.each(function (index) {
				initPhotoSwiper("#" + $(this).attr('id') + " .gallery-item");
			});
		}

		initPhotoSwiper(".entry-content .single-image");

		/*
		 * Gestione altezze uguali 
		 */
		if ($(window).width() > 767) {
			$('.equal-height').equalHeights();
			$('.data-news').equalHeights();
			$('section.highlights .item .title').equalHeights();
		}
	});

	$j(window).resize(function () {
		
		slideMobile();

	});

})(jQuery);
